import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonList, IonSpinner, IonText } from "@ionic/react";
import { StudentEnrollmentFromServer } from "../../../data-types/Types";
import { useData } from "../services/DataProvider";

const ClassOverview:React.FC<{studentEnrollment: StudentEnrollmentFromServer}> = ({studentEnrollment, ...props})=>{
  //const link = (window.location.port === "443"? "https://": "http://") + window.location.hostname.toLowerCase() + (window.location.port!=="443"? ":"+window.location.port : "") + "/class/" + classCode;
  const link = window.location.href;
  const classPath = window.location.pathname;

  const {synchingAssignmentStatuses, assignmentStatuses} = useData();

  const activeAssignments = assignmentStatuses.filter((status)=>{return status.enrollmentID === studentEnrollment.id && status.status === "incomplete"})
  const completedAssignments = assignmentStatuses.filter((status)=>{return status.enrollmentID === studentEnrollment.id && status.status === "complete"})

  return <>
    <IonCard>
      <IonCardHeader><IonCardTitle><IonText color="success">Class Login Information</IonText></IonCardTitle></IonCardHeader>
      <IonCardContent>
        <IonList>
          <IonItem>Class Code: {studentEnrollment.classCode}</IonItem>
          <IonItem>Link to Class:&nbsp;<a href={link}>{link}</a></IonItem>
          <IonItem>Student Password:&nbsp;<b>{studentEnrollment.studentPassword}</b></IonItem>
        </IonList>
        <p><IonText color="danger">Please record your login information somewhere and keep it private. There is no way to recover your account if you forget this information.</IonText></p>
      </IonCardContent>
    </IonCard>
    {synchingAssignmentStatuses?
    <IonCard>
      <IonCardHeader><IonCardTitle>Loading Assignments...</IonCardTitle></IonCardHeader>
      <IonCardContent>
        <IonSpinner />
      </IonCardContent>
    </IonCard>
    :
    <>
    <IonCard>
      <IonCardHeader><IonCardTitle>Active Assignments</IonCardTitle></IonCardHeader>
      <IonCardContent>
        {activeAssignments.map((assignment, index)=>{
          return <>
            <h2><IonText color="primary">{assignment.title? assignment.title : "Assigned Quiz"}</IonText></h2>
            <p>
              <b>Description:</b> {assignment.description? assignment.description : "Take this short quiz to get personal feedback!"}<br/>
              <b>Estimated time to complete:</b>&nbsp;{assignment.status === "incomplete"? assignment.numRequiredQuestions : "12"} minutes<br/>
              <IonButton routerLink={classPath + "/assignment/" + assignment.assignmentID}>Start Assignment</IonButton>
            </p>
          </>
        })}
      </IonCardContent>
    </IonCard>
    <IonCard>
      <IonCardHeader><IonCardTitle>Complete Assignments</IonCardTitle></IonCardHeader>
      <IonCardContent>
        {completedAssignments.map((assignment, index)=>{
          return <div key={index}>
            <h2><IonText color="primary">{assignment.title? assignment.title : "Assigned Quiz"}</IonText></h2>
            <p>
              <b>Description:</b> {assignment.description? assignment.description : "Take this short quiz to get personal feedback!"}<br/>
              <b>Estimated time to complete:</b>&nbsp;{assignment.status === "incomplete"? assignment.numRequiredQuestions : "12"} minutes<br/>
              <IonButton routerLink={classPath + "/assignment/" + assignment.assignmentID}>See Feedback</IonButton>
            </p>
          </div>
        })}
      </IonCardContent>
    </IonCard>
    </>
    }

  </>
}

export default ClassOverview;