import { IonPage, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router";
import CreateClass from "./components/CreateClass";
import ForgotPassword from "./components/ForgotPassword";
import LogIn from "./components/Login";
import Menu from "./components/Menu";
import SignUp from "./components/SignUp";
import About from "./pages/About";
import AccountManagement from "./pages/AccountManagement";
import MenuPage from "./pages/MenuPage";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import { useAuth } from "./services/AuthProvider";
import ClassManagementGuard from "./pages/ClassManagementGuard";
import ClassSelector from "./components/ClassSelector";
import StudentClassView from "./pages/StudentClassView";
import CreateStandard from "./components/CreateStandard";

const AppRouter: React.FC = () => {
  //IonRouterOutlet with id "main" is used to make the menu work, but the route caching is preventing page title changes on page transitions.
  //To convert back to non-re-rendering behavior, just remove wrapping Switch.
  const {currentUser} = useAuth();

  return (
    <IonReactRouter>
      <IonSplitPane contentId="main">
        <Menu />
        {/* <IonRouterOutlet id="main"> */}
        <IonPage id="main">
          <Switch>
            <Route path="/" exact={true}>
              <Redirect to="/about" />
            </Route>

            <Route path="/create-standard" exact={true}>
              {!(currentUser?.email === "sam@edapt.app")? <Redirect to="/" /> : <MenuPage title="add a standard"><CreateStandard /></MenuPage>}
            </Route>

            <Route path="/create-class" exact={true}>
              {!currentUser? <Redirect to="/" /> : <MenuPage title="create a class"><CreateClass /></MenuPage>}
            </Route>
            <Route path="/teacher-account-settings" exact={true}>
              {!currentUser? <Redirect to="/" /> : <MenuPage title="account settings"><AccountManagement /></MenuPage>}
            </Route>
            <Route path="/manage-class/:classID" exact={true}>
              {!currentUser? <Redirect to="/" /> : <ClassManagementGuard />}
            </Route>

            <Route path="/class" exact={true}>
              <MenuPage title="student login"><ClassSelector /></MenuPage>
            </Route>
            <Route path="/class/:classCode">
              <StudentClassView/>
            </Route>

            <Route path="/teacher-login" exact={true}>
              {currentUser? <Redirect to="/" /> : <MenuPage title="teacher login"><LogIn /></MenuPage>}
            </Route>
            <Route path="/teacher-signup" exact={true}>
              {currentUser? <Redirect to="/" /> : <MenuPage title="teacher signup"><SignUp /></MenuPage>}
            </Route>
            <Route path="/forgot-password" exact={true}>
              {currentUser? <Redirect to="/" /> : <MenuPage title="teacher - reset password"><ForgotPassword /></MenuPage>}
            </Route>

            <Route path="/about" exact>
              <MenuPage title="about"><About /></MenuPage>
            </Route>
            <Route path="/privacy" exact>
              <MenuPage title="privacy policies"><Privacy /></MenuPage>
            </Route>
            <Route path="/terms" exact>
              <MenuPage title="terms and conditions"><Terms /></MenuPage>
            </Route>

            <Route>{/* Default catch-all route at end. */}
              <MenuPage title="404 - Not Found"></MenuPage>
            </Route>
          </Switch>
        </IonPage>
        {/* </IonRouterOutlet> */}
      </IonSplitPane>
    </IonReactRouter>
  );
};

export default AppRouter;