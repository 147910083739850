import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import { FormEvent, useRef, useState } from "react";
import { TeacherClass } from "../../../data-types/Types";
import { useData } from "../services/DataProvider";
import Tooltip from "./Tooltip";
import { useHistory } from "react-router";

const CreateClass:React.FC = ()=>{
  const {synching, teacherClasses, createClass} = useData();
  const [error, setError] = useState("");
  const history = useHistory();

  const displayNameRef = useRef<HTMLIonInputElement>(null);

  const handleSubmit = (event:FormEvent)=>{
    event.preventDefault(); // Prevents page from refreshing on form submit.

    const displayName = "" + displayNameRef.current?.value;
    setError("");

    if (displayName.length === 0) {
      setError("The class needs a name to keep students from being confused.");
      return;
    }

    createClass(displayName).then((result)=>{
      const createdClass = result.data as TeacherClass;
      //Redirect to created class.
      history.push("/manage-class/"+createdClass.shortCode);
    }).catch((reason)=>{
      setError(reason.message || "Failed to create class.")
    }); //TODO: Redirect to class page on successful page creation?

    setError("");
  }

  return <IonCard>
    <IonCardHeader><IonCardTitle>Create a new class</IonCardTitle></IonCardHeader>
    <IonCardContent>
      {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
      <form onSubmit={handleSubmit}>
        <IonItem>
          <IonLabel position="floating">Class Display Name</IonLabel>
          <IonInput type="text" ref={displayNameRef} placeholder="e.g. 7th period Personal Finance"/>
          {/* <IonNote slot="helper"></IonNote> */}
        </IonItem>
        <div>This name will be used to help distinguish your classes within FinLitRI.org. <IonText color="danger">Do not use your name or any students' names in the Class Display Name.</IonText><Tooltip helpText="To maintain compliance with data privacy laws, you are not permitted to release personally identifying information on this site without documented consent. Information release consent documentation is currently unsupported."></Tooltip></div>
        <IonButton expand="block" type="submit" className="ion-margin-top" disabled={synching}>
          Create
        </IonButton>
        </form>
    </IonCardContent>
  </IonCard>
}

export default CreateClass;