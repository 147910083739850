import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterLink,
  IonRow,
  IonText,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { addOutline, addSharp, archiveOutline, archiveSharp, bookmarkOutline, checkboxOutline, checkboxSharp, fingerPrintOutline, fingerPrintSharp, heartOutline, heartSharp, helpOutline, libraryOutline, librarySharp, logInOutline, logInSharp, mailOutline, mailSharp, nutritionOutline, nutritionSharp, paperPlaneOutline, paperPlaneSharp, settingsOutline, settingsSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';
import { useAuth } from '../services/AuthProvider';
import LogOutButton from './LogOutButton';
import { useData } from '../services/DataProvider';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const infoLinks = new Map<string, string>([["about", "/about"],["privacy","/privacy"],["terms","/terms"]])

const Menu: React.FC = () => {
  // TODO: Nested/Contextual menu items? Active assignments or combined overview/review page?

  const location = useLocation();
  const {currentUser, studentSignIns} = useAuth();
  const {synching, teacherClasses, checkClassCode, classCodes} = useData();

  const appPages:AppPage[] = [];
  if (currentUser?.email === "sam@edapt.app") {
    appPages.push({
      title:"standards",
      url:"/create-standard",
      iosIcon:checkboxOutline,
      mdIcon:checkboxSharp,
    });
  }
  if (!currentUser) {
    if(studentSignIns.length > 0) {
      studentSignIns.forEach((studentEnrollment)=>{
        if (!synching){
          checkClassCode(studentEnrollment.classCode);
        }
        appPages.push({
          title:classCodes.get(studentEnrollment.classCode)?.displayName || ("Class ID: " + studentEnrollment.classCode),
          url:"/class/"+studentEnrollment.classCode,
          iosIcon:libraryOutline,
          mdIcon:librarySharp,
        })
      });
      appPages.push(
        {
          title:"add a class",
          url: "/class",
          iosIcon: addOutline,
          mdIcon: addSharp
        }
      );
    } else {
      appPages.push(
        {
          title:"student login",
          url: "/class",
          iosIcon: logInOutline,
          mdIcon: logInSharp
        }
      );
      // appPages.push(
      //   {
      //     title:"teacher login",
      //     url: "/teacher-login",
      //     iosIcon: logInOutline,
      //     mdIcon: logInSharp
      //   }
      // );
    }
  }
  if (currentUser) {
    for (let teacherClass of teacherClasses) {
      appPages.push(
        {
          title:`${teacherClass.displayName}`,
          url:`/manage-class/${teacherClass.shortCode}`,
          iosIcon: nutritionOutline,
          mdIcon: nutritionSharp
        }
      )
    }

    appPages.push(
      {
        title:"create a class",
        url:"/create-class",
        iosIcon: addOutline,
        mdIcon: addSharp
      }
    )
    appPages.push(
      {
        title:"account settings",
        url: "/teacher-account-settings",
        iosIcon: fingerPrintOutline,
        mdIcon: fingerPrintSharp
      }
    )
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonGrid><IonRow><IonCol style={{maxWidth:"4em"}}><IonImg src="./assets/icon/icon.png"/></IonCol>
            <IonCol>
              <IonGrid>
                <IonRow><IonListHeader>Fin Lit RI</IonListHeader></IonRow>
                <IonRow><IonNote style={{margin:"0", width:"100%", textAlign:"left"}}>{currentUser? currentUser.email: ""}</IonNote></IonRow>
                <IonRow>{currentUser || studentSignIns.length > 0? <IonNote style={{margin:"0", width:"100%", textAlign:"left"}}><LogOutButton /></IonNote>: <></>}</IonRow>
              </IonGrid>
            </IonCol>
          </IonRow></IonGrid>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>info pages</IonListHeader>
          {Array.from(infoLinks.entries()).map(([label, address], index) => (
            <IonItem lines="none" key={index} routerLink={address}>
              <IonIcon slot="start" icon={helpOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
        <br />
        <p><a href="mailto:contact@edapt.app">Experiencing a bug? Email Support.</a></p>
        <IonText color="medium" style={{fontSize:".7em"}}><p>All site content copyright © Edapt Technologies, 2022<br/> unless otherwise noted.</p></IonText>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
