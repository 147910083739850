import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthProvider";
import {EducationalStandard, ServerFCQ, StudentStatusFromServer, TeacherClass, TeacherClassFromServer} from '../../../data-types/Types';
import { functions } from "../App";
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'

export const DataContext = React.createContext({synching:false, teacherClasses: [] as TeacherClass[], createClass: (displayName:string)=>{return {} as Promise<HttpsCallableResult<unknown>>}, classCodes: new Map<string, TeacherClassFromServer>(), checkClassCode: (classCode: string) => {return {} as Promise<TeacherClassFromServer>}, questions: [] as ServerFCQ[], synchingAssignmentStatuses: false, assignmentStatuses: [] as StudentStatusFromServer[], updateStatus: (assignment: StudentStatusFromServer, response: string)=>{return {} as Promise<void>}, synchingAdmin: false, adminCreateRecord: (collectionName: string, record: any) => {return {} as Promise<void>}, createOwnedRecord: (collectionName: string, record: any) => {return {} as Promise<void>}, standards: [] as EducationalStandard[], teacherData: {} as any}); //TODO: Type context //TODO: Type for questions.

export function useData() {
  return useContext(DataContext);
}

export const DataProvider: React.FC = ({children, ...props}) => {
  // TODO: Add synching safety (preventing cascading state changes causing multiple requests)?

  const {currentUser, studentSignIns} = useAuth();

  let [synching, setSynching] = useState(false);
  const [teacherClasses, setTeacherClasses] = useState<TeacherClassFromServer[]>([])
  const [classCodes, setClassCodes] = useState<Map<string, TeacherClassFromServer>>(new Map<string, TeacherClassFromServer>())
  const [questions, setQuestions] = useState<ServerFCQ[]>([]); // TODO: fix and set properly.
  const [synchingAssignmentStatuses, setSynchingAssignmentStatuses] = useState(true);
  const [assignmentStatuses, setAssignmentStatuses] = useState<StudentStatusFromServer[]>([]);
  const [synchingAdmin, setSynchingAdmin] = useState(true);
  const [standards, setStandards] = useState<EducationalStandard[]>([]);
  const [teacherData, setTeacherData] = useState<any>({}); // TODO: Proper Typings
  const [synchingTeacherData, setSynchingTeacherData] = useState(false);
  const [synchingRecord, setSynchingRecord] = useState(false);

  synching = synching || synchingAssignmentStatuses || synchingAdmin || synchingTeacherData || synchingRecord; // Block synching-actions from any server interactions.

  standards.sort((a, b)=>{
    const d = "."; // A delimiter that comes before letters or numbers will lead to correct sorting when they are next to each other.
    const index1 = a.region + d + a.subject + d + a.grade + d + a.topic + d + a.standardIndex + d + a.substandardIndex;
    const index2 = b.region + d + b.subject + d + b.grade + d + b.topic + d + b.standardIndex + d + b.substandardIndex;
    // if (index1 > index2) {
    //   return 1;
    // } else {
    //   return -1;
    // }
    return index1.localeCompare(index2, undefined, {numeric: true, sensitivity: 'base'}); // Uses local locale settings to smartly order strings.
  });

  const serverCreateClass = httpsCallable(functions, "createUpdateTeacherClass"); //For some reason, this has to happen inside the component to avoid an initialization error.
  const serverFetchClasses = httpsCallable(functions, "fetchTeacherClasses");
  const serverConfirmClassCode = httpsCallable(functions, "confirmClassCode");
  const serverFetchQuestions = httpsCallable(functions, "getQuestions");
  const serverGetAssignmentStatuses = httpsCallable(functions, "getAssignmentStatuses");
  const serverUpdateAssignmentWithResponse = httpsCallable(functions, "updateAssignmentWithResponse");
  const serverAdminCreateUpdateRecord = httpsCallable(functions, "adminCreateUpdateRecord");
  const serverCreateUpdateOwnedRecord = httpsCallable(functions, "createUpdateOwnedRecord");
  const serverGetStandards = httpsCallable(functions, "getStandards");
  const serverFetchTeacherData = httpsCallable(functions, "fetchTeacherData");

  const createClass = (displayName:string)=>{
    setSynching(true);
    const document:TeacherClass = {apiVersion:1, teacherUID: "" + currentUser?.uid, displayName};
    const outcome = serverCreateClass(document).then((result)=>{
      setTeacherClasses([...teacherClasses, result.data as TeacherClassFromServer])
      setSynching(false);
      return result;
    }).catch((reason)=>{
      // console.log(JSON.stringify(reason));
      setSynching(false);
      throw reason;
    });
    return outcome;
  }

  const checkClassCode = async (classCode: string) => {
    if(classCodes.has(classCode)) {
      return classCodes.get(classCode) as TeacherClassFromServer; // Assert that this will always be defined.
    } else {
      setSynching(true);
      return serverConfirmClassCode(classCode).then((result)=>{
        setSynching(false);
        classCodes.set(classCode, result.data as TeacherClassFromServer);
        setClassCodes(Object.assign(classCodes));
        return result.data as TeacherClassFromServer;
      }).catch((reason)=>{
        setSynching(false);
        if(reason.code==="not-found") {
          classCodes.set(classCode, {displayName: "Class Not Found", apiVersion: 1, teacherUID: "", id: "", shortCode: classCode, teacherEmailHash: ""});
          setClassCodes(Object.assign(classCodes));
        }
        throw reason;
      })
    }
  }

  const updateStatus = async (assignment: StudentStatusFromServer, response: string) => {
    setSynchingAssignmentStatuses(true);
    await serverUpdateAssignmentWithResponse({assignmentStatus: assignment, response: response}).then((result)=>{
      const newStatus = result.data as StudentStatusFromServer;
      const oldIndex = assignmentStatuses.findIndex((value)=>{return value.assignmentID === assignment.assignmentID});
      assignmentStatuses[oldIndex] = newStatus;
      setAssignmentStatuses(Object.assign(assignmentStatuses));
    }).finally(()=>{
      setSynchingAssignmentStatuses(false);
    })
  }

  const adminCreateRecord = async (collectionName: string, record: any) => {
    setSynchingAdmin(true);
    const outcome = serverAdminCreateUpdateRecord({collectionName, record}).then((result)=>{
      if (collectionName === "standards") {
        setStandards([...standards, result.data as EducationalStandard]);
      } else if (collectionName === "questions") {
        setQuestions([...questions, result.data as ServerFCQ]);
      }
      setSynchingAdmin(false);
      //return result;
    }).catch((reason)=>{
      // console.log(JSON.stringify(reason));
      setSynchingAdmin(false);
      throw reason;
    });
    return outcome;
  }

  const createOwnedRecord = async (collectionName: string, record: any) => {
    setSynchingRecord(true);
    const outcome = serverCreateUpdateOwnedRecord({collectionName, record}).then((result)=>{
      if (collectionName === "assignments") {
        setSynchingTeacherData(true);
        serverFetchTeacherData().then((result)=>{
          setSynchingTeacherData(false);
          setTeacherData(result.data);
        }).catch((reason)=>{
          console.log(JSON.stringify(reason));
          setSynchingTeacherData(false);
        });
      }
      setSynchingRecord(false);
      //return result;
    }).catch((reason)=>{
      // console.log(JSON.stringify(reason));
      setSynchingRecord(false);
      throw reason;
    });
    return outcome;
  }

  useEffect(()=>{
    if (currentUser) {
      setSynching(true);
      serverFetchClasses().then((result)=>{
        setSynching(false);
        setTeacherClasses(result.data as TeacherClassFromServer[]);
      }).catch((reason)=>{
        console.log(JSON.stringify(reason));
        setSynching(false);
      });

      setSynchingTeacherData(true);
      serverFetchTeacherData().then((result)=>{
        setSynchingTeacherData(false);
        setTeacherData(result.data);
      }).catch((reason)=>{
        console.log(JSON.stringify(reason));
        setSynchingTeacherData(false);
      });
    }

    //TODO: Do properly with updated synching value
    serverFetchQuestions().then((result)=>{
      setQuestions(result.data as ServerFCQ[]);
    });

    setSynchingAdmin(true);
    serverGetStandards().then((result)=>{
      setStandards(result.data as any);
    }).finally(()=>{
      setSynchingAdmin(false);
    });

    //Update assignments based on student sign-ins
    setSynchingAssignmentStatuses(true);
    serverGetAssignmentStatuses(studentSignIns.map((enrollment)=>{return enrollment.id})).then((result)=>{
      setAssignmentStatuses(result.data as StudentStatusFromServer[]);
    }).finally(()=>{
      setSynchingAssignmentStatuses(false);
    });
  }, [currentUser, studentSignIns]); // end useEffect.

  return ( 
    <DataContext.Provider value={{synching, teacherClasses, createClass, classCodes, checkClassCode, questions, synchingAssignmentStatuses, assignmentStatuses, updateStatus, synchingAdmin, adminCreateRecord, createOwnedRecord, standards, teacherData}}>
      {children}
    </DataContext.Provider>
   );
}

export default DataProvider;