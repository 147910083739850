import { jsonEval } from "@firebase/util";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText, IonTextarea } from "@ionic/react";
import { stringify } from "querystring";
import { FormEvent, useRef, useState } from "react";
import { EducationalStandard, ServerFCQ } from "../../../data-types/Types";
import { useData } from "../services/DataProvider";
import CreateQuestion from "./CreateQuestion";
import DisplayFCQ from "./DisplayFCQ";
import Expandable from "./Expandable";
import Standard from "./Standard";

const CreateStandard:React.FC = ({ ...props})=>{
  const {synchingAdmin, standards, adminCreateRecord, questions} = useData();
  const standardRef = useRef<HTMLIonInputElement>(null);
  const substandardRef = useRef<HTMLIonInputElement>(null);
  const gradeLevelRef = useRef<HTMLIonSelectElement>(null);
  const titleRef = useRef<HTMLIonInputElement>(null);
  const descriptionRef = useRef<HTMLIonTextareaElement>(null);
  const topicSelectRef = useRef<HTMLIonSelectElement>(null);

  const [error, setError] = useState("");

  const topicQuizIDs = {
    "4": {
      "I. Earning Income": "yfUPIKYDK7Q7gPZhhwIW",
      "II. Spending": "w6Ky1miYyeDyqL2q2d2H", 
      "III. Saving": "ood6qfCWAcwbyr6SCcQi",
      "IV. Investing": "FrBvtLW5pvFm9E6goBzi",
      "V. Managing Credit": "EUDCyCe7rOGgi9KkpWKt",
      "VI. Managing Risk": "5w9Pm74NlFSOewcu2xsj"
    },
    "8": {
      "I. Earning Income": "hFo5y7X3pli2iXOE7qqB",
      "II. Spending": "QARrHEfsOBTIzEw8Ishw", 
      "III. Saving": "vD8j3IWPJ7WdYDMGryOe",
      "IV. Investing": "n48EFoQwWhUf39RDOGRo",
      "V. Managing Credit": "iTB9H61oiEiNTY07s6A3",
      "VI. Managing Risk": "SiXY5Rj4ic690lxzsLtN"
    },
    "12": {
      "I. Earning Income": "ZmQZtYyOu6zCmqKy0oNF",
      "II. Spending": "wflAiJqOFTA7IGSMU0BU", 
      "III. Saving": "sshfwH3eNEcEGmblusDD",
      "IV. Investing": "T4Ki1Jj4hKFcwwgPtnyu",
      "V. Managing Credit": "KLlJ4vaBGlpzDKe1XRMt",
      "VI. Managing Risk": "oxZy6OAohlpwbRXByi9E"
    },
  };

  const topics = Object.keys(topicQuizIDs[12]);

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.
    // TODO
    setError("");
    const standardIndex = standardRef.current?.value;
    const substandardIndex = substandardRef.current?.value;
    const title = titleRef.current?.value;
    const description = descriptionRef.current?.value;
    const selectedTopic = topicSelectRef.current?.value;
    const gradeLevel = gradeLevelRef.current?.value;

    if (!standardIndex || !substandardIndex || !title || !description || !selectedTopic || !gradeLevel) {
      setError("All fields must be filled.");
      return;
    }

    const record:EducationalStandard = {
      title: "" + title,
      description: description,
      year:2021,
      region:"RI",
      subject:"FinLit",
      grade: gradeLevel,
      topic: selectedTopic,
      standardIndex:"" + standardIndex,
      substandardIndex:"" + substandardIndex,
      documentURL:"https://www.councilforeconed.org/national-standards-for-personal-financial-education/",
    }
    // record.id = `${record.region}.${record.year}.${record.subject}.${record.grade}.${record.topic}.${record.standardIndex}${record.substandardIndex}`;
    // Decided to just use default id in the database, but render codes in the display as appropriate.

    await adminCreateRecord("standards", record);
    standardRef.current.value = "";
    substandardRef.current.value = "";
    titleRef.current.value = "";
    descriptionRef.current.value = "";
  }

  const structuredQuestions = new Map<string, ServerFCQ[]>(); // Map from standard id to list of applicable questions
  const unassignedQuestions = [] as ServerFCQ[];
  questions.forEach((question)=>{
    const questionStandards = question.standardsCodes || [];
    if (questionStandards.length < 1) {
      unassignedQuestions.push(question);
    } else {
      questionStandards.forEach((standard) => {
        const questionList = structuredQuestions.get(standard) || [];
        questionList.push(question)
        structuredQuestions.set(standard, questionList);
      });
    }
  });

  return <>
    <IonCard>
      <IonCardContent>
        <p>Content is assumed to be based on the RI-adopted Financial Literacy Standards.</p>
        {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
        <form onSubmit={handleSubmit}>
          <IonItem>
            <IonLabel>Financial Literacy Pillar</IonLabel>
            <IonSelect ref={topicSelectRef}>
              {topics.map((topic, index)=>{return <IonSelectOption key={index}>{topic}</IonSelectOption>})}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>Grade Level for Standards</IonLabel>
            <IonSelect ref={gradeLevelRef}>
              {["4", "8", "12"].map((grade, index)=>{return <IonSelectOption key={index}>{grade}</IonSelectOption>})}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Standard Number</IonLabel>
            <IonInput type="text" ref={standardRef}/>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Substandard Letter/Number</IonLabel>
            <IonInput type="text" ref={substandardRef}/>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Title/Topic</IonLabel>
            <IonInput type="text" ref={titleRef}/>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Description</IonLabel>
            <IonTextarea ref={descriptionRef} autoGrow/>
          </IonItem>
          <IonButton expand="block" type="submit" className="ion-margin-top" disabled={synchingAdmin}>
            Create
          </IonButton>
        </form>
      </IonCardContent>
    </IonCard>
    {standards.map((standard, index)=>{
      return <IonCard key={index}>
        <IonCardContent>
          <Standard standard={standard} showID/>
          <>
            <p>Create Question: <Expandable><CreateQuestion defaultValues={{
              questionType: "FCQ",
              quizID: topicQuizIDs[(standard.grade as keyof typeof topicQuizIDs)][(standard.topic as keyof typeof topicQuizIDs["12"])] || "", //TODO
              standardsCodes: [standard.id],
            }}/></Expandable></p>
          </>
          {(structuredQuestions.get(standard.id || "") || []).map((question, index)=>{
            return <DisplayFCQ question={question} key={index}/>
          })}
        </IonCardContent>
      </IonCard>
    })}
    <IonCard>
      <IonCardHeader><IonCardTitle>Questions without Standards</IonCardTitle></IonCardHeader>
      <IonCardContent>
        {unassignedQuestions.map((question, index)=>{
          return <DisplayFCQ question={question} key={index}/>
        })}
      </IonCardContent>
    </IonCard>
  </>
}

export default CreateStandard;