import { EducationalStandard } from "../../../data-types/Types";

const PresentForcedChoiceQuestion:React.FC<{standard:EducationalStandard, showID?: boolean}> = ({standard, showID, ...props})=>{

  return <div style={{border:"1px solid var(--ion-color-primary)", borderRadius:"1em", padding:"1em", margin:".25em"}}>
    <h2><b>{standard.title}</b> - {standard.region}.{standard.year}.{standard.subject}.({standard.topic}).{standard.grade}-{standard.standardIndex}{standard.substandardIndex}</h2><br/>
    <p>{standard.description}</p>
    {showID? <p>{standard.id}</p> : <></>}
  </div>
}

export default PresentForcedChoiceQuestion;