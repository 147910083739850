import DeleteProfile from "../components/DeleteProfile";
import UpdateProfile from "../components/UpdateProfile"

const AccountManagement:React.FC = ()=>{
  return <>
    <UpdateProfile />
    <DeleteProfile />
  </>
  
  
}

export default AccountManagement;