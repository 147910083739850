import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRouterLink } from "@ionic/react";
import Expandable from "../components/Expandable";
import Tooltip from "../components/Tooltip";

const Privacy:React.FC = ()=>{
  return <>
  <IonCard>
    <IonCardHeader><IonCardTitle>Overview</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <p>FinLitRI.org takes the privacy of its users (teachers, students, and others) very seriously. This is not only important for the safety and convenience of everyone involved; data privacy is also a critical aspect of giving students the freedom to take intellectual risks. Our legally binding Privacy Policy can be viewed by clicking the link below. A bullet-point summary is reproduced here for your benefit. Furthermore, FinLitRI.org is compliant with (or in many cases, exempt from) all major privacy regulations, including those that apply to other geographic regions.</p>
      <ul>
        <li>No personally identifiable information is collected from or about students at any time.</li>
        <li>Emails will be collected from teachers, researchers, or other assessment administrators. The email can be updated or deleted at any time, and is only used for enabling password reset or account data recovery. A cryptographic hash (anonymous signature) of the email will be kept to enable account recovery in the event of accidental deletion.</li>
        <li><i>Anonymous</i> educational data (testing results and insights, anonymous engagement behaviors, etc.) may be accessed, shared, or published at any time by FinLitRI.org without notice for education research purposes. Teachers also have the ability to access or share anonymous data that they have collected using the system.</li>
        <li>FinLitRI.org features a tool for generating anonymous ids and for locally (only on the teacher's machine) associating school grading ids with the anonymous ids used by FinLitRI.org. The file with this correspondence is stored only on the teacher's computer, under their full control, and cannot be recovered if deleted.</li>
      </ul>
      <h1>Full Legal Privacy Policy</h1>
      <Expandable>
        <h1>Privacy Policy of FinLitRI.org</h1>

        <p>Edapt Technologies, LLC operates the FinLitRI.org website, which provides the SERVICE.</p>

        <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information provided to the Fin Lit RI website.</p>

        <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

        <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at <IonRouterLink routerLink="/terms">FinLitRI.org/terms</IonRouterLink>, unless otherwise defined in this Privacy Policy.</p>

        <h2>Information Collection and Use</h2>

        <p>We may require you to provide us with an email address in order to access certain features of the website. This email will only be used for non-anonymous accounts for the purpose of providing password reset and account recovery functionality. Email addresses will never be shared with third parties except for providing these functionalities, and will not be used for any other purpose. Emails can be changed or deleted by accessing the account settings page on the website. Children under the age of 13 should not register for a non-anonymous account. Non-anonymous accounts are intended for teachers, administrators, researchers, or other individuals interested in administering educational assessments, and are not intended for children under the age of 13.</p>

        <h2>Anonymous Education Data</h2>

        <p>One of the primary purposes of this Service is to collect, analyze, and present education data. This data is always collected anonymously and will never be associated in our records with any personally-identifiable information, or any information that could be combined in order to personally identify the originator of the data. This anonymous data may be accessed, shared, or published at any time without notice for the purpose of education research. Non-anonymous account users may also have the ability through the Service to share anonymous education data that has been voluntarily provided to them.</p>

        <h2>Log Data</h2>

        <p>Whenever you visit our Service, we may elect to maintain records of interaction called Log Data. Log data may include time and date of page access, actions taken within the website, and other statistics useful for maintaining and improving the Service.</p>

        <h2>Cookies</h2>

        <p>Cookies are files with small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the website that you visit and are stored on your computer's hard drive. You may receive cookies that facilitate login upon reloading the website. Cookies will be used for no other purpose by this website. Cookies can be removed or blocked at any time using your web browser settings.</p>

        <h2>Links to Other Sites</h2>

        <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

        <h2>Changes to This Privacy Policy</h2>

        <p>Edapt Technologies, LLC reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes by updating this page and placing a prominent notification on the website. Your continued use of the website and/or Service available after such modifications will constitute your: (a) acknowledgement of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>

        <h2>Contact Us</h2>

        <p>If you have any questions or comments regarding this Privacy Policy, do not hesitate to email <a href="mailto:contact@edapt.app">contact@edapt.app</a>.</p>

        <p>Effective as of January 1, 2022</p>
      </Expandable>
    </IonCardContent>
  </IonCard>
  <IonCard>
    <IonCardHeader><IonCardTitle>FERPA Compliance</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <Expandable>
        <p>The <a href="https://studentprivacy.ed.gov/node/548/">Family Educational Rights and Privacy Act (FERPA)</a> is the law most relevant to student data privacy in the US. Although care has to be taken to ensure compliance with the regulations, the policy is neither unreasonable nor overly restrictive. The technical constraints boil down to a few main points:</p>
        <ol>
          <li>All of the data a district maintains about a student must be made available upon request to the student <Tooltip helpText="A minor subtlety: if the student is covered by the Individuals with Disabilities in Education Act (IDEA), notification must still be provided to the legal parent or guardian even after the student comes of age.">(if over 18)</Tooltip> or their legal guardian.</li>
          <li>The student (if of legal age) or their guardian must be able to request amendments to the student's data, which the school can honor by adopting the amendment or by appending a statement about the contested information (following a hearing procedure).</li>
          <li>Personally identifiable information may not be disclosed to a third-party without the consent of the student (if of age) or legal guardian except to those under the direct control of the school system for the purpose of furthering the education of the student.</li>
        </ol>

        <p>Use of FinLitRI.org is exempt from FERPA consent and disclosure requirements (item 3 above) because no personally identifiable information is ever collected about the student (FERPA Section <a href="https://studentprivacy.ed.gov/node/548/#0.1_se34.1.99_131">99.31</a>.(b).(2)). Anonymous ids are used for grading and education research purposes. These ids can only be de-anonymized by teachers who keep a private list offline relating anonymous ids to personally identifying student information.</p>

        <p>FinLitRI.org is also happy to assist in any technical audits affirming its exemption from FERPA consent and disclosure requirements. For convenience, FinLitRI.org provides an interface for generating a table linking student grading ids with the anonymous ids used by the system. This interface runs only on the teacher's computer (no data is uploaded), and it is the teacher's responsibility to store this record on their own device; no other copy exists. Using standard inspection tools in any modern web browser, it is easy to verify that no information is sent over the network while the table is being generated or used by the teacher. Feel free to email <a href="mailto:contact@edapt.app">contact@edapt.app</a> for assistance in running a technical audit.</p>
      </Expandable>
    </IonCardContent>
  </IonCard>
  <IonCard>
    <IonCardHeader><IonCardTitle>PPRA Compliance</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <Expandable>
        <p>The <a href="https://studentprivacy.ed.gov/content/ppra">Protection of Pupil Rights Amendment</a> regulates the collection of sensitive data from students, specifically: political affiliations of a student or parent; mental or psychological problems of the student or family; sex behavior or attitudes; illegal, anti-social, self-incriminating, or demeaning behavior; critical appraisals of those with close family relationships; legally recognized privileged or analogous relationships; religious practices; or income.</p>
        <p>FinLitRI.org does not collect information related to any of the areas protected by PPRA, and so is exempt from the consent and disclosure requirements described.</p>
      </Expandable>
    </IonCardContent>
  </IonCard>
  <IonCard>
    <IonCardHeader><IonCardTitle>COPPA Compliance</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <Expandable>
        <p>The <a href="https://www.ecfr.gov/current/title-16/part-312">Children's Online Privacy Protection Rule (COPPA)</a> imposes restrictions on web services directed at children under the age of 13, or that collect personally identifiable information from children under the age of 13. COPPA requires <Tooltip helpText="Consent for use (by students under 13) of a third-party service that collects its own data can be provided by teachers or other school officials only if the data is only used for the education of the student and for no other commercial purpose.">parental consent</Tooltip> for children under the age of 13 to provide any of the following: first and last name; physical address; online contact information; a screen or user name that functions as contact information; a telephone number; a Social Security number; a persistent identifier, such as an IP address, that can be used to identify a user across different websites or online services; a media file containing the child's image or voice; geolocation information; or information that can be combined to or with any of the previous items.</p>
        <p>FinLitRI.org is exempt from COPPA requirements as it does not collect any data from children that meets the definition of Personal Information.</p>
      </Expandable>
    </IonCardContent>
  </IonCard>
  <IonCard>
    <IonCardHeader><IonCardTitle>SOPIPA Compliance</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <Expandable>
        <p>The Student Online Personal Information Privacy Act (SOPIPA) applies specifically to California students and restricts the use of student data for advertising purposes by third-party service providers. Although FinLitRI.org is designed to align with Rhode Island educational standards, even teachers in California can rest easy - no personally identifiable information is collected about students, and no user data (including teacher data) is ever sold or used for third-party advertising.</p>
      </Expandable>
    </IonCardContent>
  </IonCard>
  <IonCard>
    <IonCardHeader><IonCardTitle>GDPR Compliance</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <Expandable>
        <p>The <a href="https://gdpr-info.eu/">General Data Protection Regulation</a> applies to personal data collected from users in the European Union. It generally requires: transparency in how the data is used; explicit consent for use of data; use of data only for as long and for purposes that are necessary to the service; security and confidentiality of data.</p>
        <p>Although FinLitRI.org is designed primarily for users in Rhode Island, it maintains compliance with GDPR. The only personal data collected by the system are emails of teachers or other assessment administrators; these emails are only used for the purpose of enabling password resets and account data recovery. Users must explicitly consent to the use of their email to register for an account, and can delete their account at any time.</p>
      </Expandable>
    </IonCardContent>
  </IonCard>
  </>
}

export default Privacy;