import { useParams } from "react-router";
import ClassManagement from "../components/ClassManagement";
import { useData } from "../services/DataProvider";
import MenuPage from "./MenuPage";

const ClassManagementGuard:React.FC = ({...props})=>{
  const { classID } = useParams<{classID:string}>();
  const { synching, teacherClasses } = useData();
  const targetClasses = teacherClasses.filter((teacherClass)=>{return teacherClass.shortCode===classID});
  const targetClass = (targetClasses.length > 0) ? targetClasses[0] : null;

  //TODO: Redirect or 404 if class is not under management...

  return targetClass? <MenuPage title={"Manage: " + targetClass?.displayName}><ClassManagement classID={classID}/></MenuPage> : <>
    {synching? <MenuPage title="Loading Class"></MenuPage> : <MenuPage title="404: Can't Find Class"></MenuPage>}
  </>
}

export default ClassManagementGuard;