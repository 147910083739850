import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSpinner } from "@ionic/react";
import { useState } from "react";
import { useParams } from "react-router";
import { EducationalStandard, ForcedChoiceQuestion } from "../../../data-types/Types";
import { useData } from "../services/DataProvider";
import Expandable from "./Expandable";
import PresentForcedChoiceQuestion from "./PresentForcedChoiceQuestion";
import Standard from "./Standard";

const DoAssignment:React.FC = ({ ...props})=>{
  const { assignmentID } = useParams<{assignmentID:string}>();

  const {standards, synching, assignmentStatuses, synchingAssignmentStatuses, updateStatus} = useData(); //TODO: Synching guard
  const assignment = assignmentStatuses.filter((assignment)=>{return assignment.assignmentID===assignmentID})[0];

  let completedStandards = [] as EducationalStandard[];
  let nextStandards = [] as EducationalStandard[];
  let futureStandards = [] as EducationalStandard[];

  if (assignment && assignment?.status==="complete") {
    completedStandards = standards.filter((standard)=>{return assignment.completedStandardIDs.includes(standard.id || "")});
    nextStandards = standards.filter((standard)=>{return assignment.nextStandardsToReview.includes(standard.id || "")});
    futureStandards = standards.filter((standard)=>{return assignment.otherStandardsToReview.includes(standard.id || "")});
  }

  // const questionsStandIn = [
  //   {
  //     prompt: "What is the difference between a Roth IRA and a \"Traditional\" IRA?",
  //     orderedChoices: ["The Traditional IRA is done through an employer.", "The Traditional IRA defers income tax until retirement.", "The Traditional IRA can't be contributed to after age 59 1/2."]
  //   }
  // ];

  // const questionsStandIn: ForcedChoiceQuestion[] = questions.map((serverQuestion)=>{
  //   return {
  //     prompt: serverQuestion.prompt,
  //     orderedChoices: [...serverQuestion.distractors, serverQuestion.correctAnswer]
  //   };
  // });

  const handleAnswer = (answerIndex:number)=>{
    // setQuestionIndex((oldValue)=>{return oldValue + 1});
    updateStatus(assignment, assignment.status==="incomplete"?assignment.nextQuestion.orderedChoices[answerIndex]:"");
  }

  return <>
    <IonCard>
      {synchingAssignmentStatuses || !assignment?
      <>
        <IonCardHeader><IonCardTitle>Loading Assignment...</IonCardTitle></IonCardHeader>
        <IonCardContent>
          <IonSpinner />
        </IonCardContent>
      </>
      :
      <>
        <IonCardHeader><IonCardTitle>{assignment.title? assignment.title : "Investing Quiz"}</IonCardTitle></IonCardHeader>
        <IonCardContent>
          {/* {questions.length? 
            (questionIndex < questions.length)?<>
            <p><b>Progress:</b> Question {questionIndex + 1} of {questions.length} (required).</p>
            <PresentForcedChoiceQuestion question={questionsStandIn[questionIndex]} answerCallback={handleAnswer}/>
            </>
            : <p>Quiz complete!</p>
          : 
          <p>Quiz Loading</p>
          } */}
          {assignment.status === "incomplete"?
          <>
            <p>Question {assignment.numCompletedQuestions + 1} of {assignment.numRequiredQuestions} (max).</p>
            <PresentForcedChoiceQuestion question={assignment.nextQuestion} answerCallback={handleAnswer}/>
          </>
          :
          <>
            {completedStandards.length > 0 ? <>
              <h1>Mastered Topics</h1>
              <Expandable>{completedStandards.map((standard, index)=>{return <Standard standard={standard} key={index}></Standard>})}</Expandable>
            </> : <></>}
            {nextStandards.length > 0 ? <>
              <h1>Next Topics</h1>
              {nextStandards.map((standard, index)=>{return <Standard standard={standard} key={index}></Standard>})}
            </> : <></>}
            {futureStandards.length > 0 ? <>
              <h1>Future Topics</h1>
              <Expandable>{futureStandards.map((standard, index)=>{return <Standard standard={standard} key={index}></Standard>})}</Expandable>
            </> : <></>}
          </>
          }
        </IonCardContent>
      </>
      }
    </IonCard>
  </>
}

export default DoAssignment;