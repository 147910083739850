import { IonText } from "@ionic/react"
import { ServerFCQ } from "../../../data-types/Types"

const DisplayFCQ:React.FC<{question:ServerFCQ}> = ({question, ...props}) =>{
  return <div style={{border:"1px solid var(--ion-color-secondary)", borderRadius:"1em", padding:"1em", margin:".25em"}}>
    <h2><b>{question.prompt}</b></h2><br/>
    <IonText color="success"><p>{question.correctAnswer}</p></IonText>
    {question.distractors.map((distractor, index)=>{
      return <IonText color="medium" key={index}>
        <p>{distractor}</p>
      </IonText>
    })}
    <p>ID: {question.id}</p>
  </div>
}

export default DisplayFCQ