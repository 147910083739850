import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonInput, IonItem, IonLabel, IonRouterLink, IonText } from "@ionic/react";
import { FormEvent, useRef, useState } from "react";
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
// import { useHistory } from "react-router";
// import { useAuth } from "reactfire";
import { useAuth } from "../services/AuthProvider";
import Expandable from "./Expandable";

const SignUp: React.FC = ({...props}) => {
  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const confirmPasswordRef = useRef<HTMLIonInputElement>(null);
  const readAgreementsRef = useRef<HTMLIonCheckboxElement>(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {signup} = useAuth(); 
  // const auth = useAuth();

  // const history = useHistory();

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    if(!readAgreementsRef.current?.checked) {
      setError("You must agree to the Privacy Policy and Terms and Conditions.");
      return;
    }

    // if (("" + passwordRef.current?.value).length <= 0) { // This is effectively checked by Firebase's auth requirements.
    //   setError("Password cannot be empty.");
    //   return;
    // }

    if (passwordRef.current?.value !== confirmPasswordRef.current?.value) {
      setError("Passwords do not match.");
      return;
    }

    setError('');
    setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
    await (signup("" + emailRef.current?.value, "" + passwordRef.current?.value)?.then(()=>{
      setLoading(false);
      // history.push("/"); //Redirect to home page/dashboard after login.
      // REDIRECT SHOULD HAPPEN IN THE ROUTER.
    }).catch((reason)=>{
      setError('Failed to create an account: ' + (reason.message || ""));
      //console.log(reason); //TODO: Present to the user.
      setLoading(false);
    }));
  }

  //TODO: Set color of error based on ionic colors
  return <>
  <IonCard>
    <IonCardHeader><IonCardTitle>Sign Up with Email</IonCardTitle></IonCardHeader>
    <IonCardContent>
      {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
      <form onSubmit={handleSubmit}>
      <IonItem>
        <IonLabel position="floating">Email</IonLabel>
        <IonInput type="email" ref={emailRef} placeholder="teacher@district.k12.ri.us"/>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Password</IonLabel>
        <IonInput type="password" ref={passwordRef}/>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Confirm Password</IonLabel>
        <IonInput type="password" ref={confirmPasswordRef}/>
      </IonItem>
      <IonItem>
        <IonCheckbox slot="start" ref={readAgreementsRef}/>
        <IonLabel>I have read and agree to the <i>Privacy Policy</i> and <i>Terms and Conditions</i> (below).</IonLabel>
      </IonItem>
      <IonButton expand="block" type="submit" className="ion-margin-top" disabled={loading}>
        Register
      </IonButton>
      </form>
      <p>Already have an account? <IonRouterLink routerLink="/teacher-login">Log In</IonRouterLink></p>
    </IonCardContent>
  </IonCard>
  <IonCard>
    <IonCardHeader><IonCardTitle>Privacy Policy</IonCardTitle></IonCardHeader>
    <Expandable><Privacy /></Expandable>
  </IonCard>
  <IonCard>
    <IonCardHeader><IonCardTitle>Terms and Conditions</IonCardTitle></IonCardHeader>
    <Expandable><Terms /></Expandable>
  </IonCard>
  </>
};

export default SignUp;

