import { IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { cloudDoneOutline, cloudDoneSharp, cloudDownloadOutline, cloudDownloadSharp, cloudUploadOutline, cloudUploadSharp } from 'ionicons/icons';
import { useEffect} from 'react';
import { useData } from '../services/DataProvider';

const MenuPage: React.FC<{title:string}> = ({title, children, ...props}) => {
  
  // For some reason (I think caching of renders that doesn't account for the side effect of changing title), the title doesn't change on the second time the page is accessed.
  // Attempting to fix by using a conditional state update.
  useEffect(()=>{
    document.title=title + " - Fin Lit RI";
  }, [title]);

  const {synching} = useData();

  // console.log("Rendering Page " + title);

  //IonPage provided by AppRouter now.
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle style={{textAlign:"center"}}>{title}</IonTitle>
          {synching? <IonButtons slot="end" style={{margin:".5em"}}>
            <IonIcon color="warning" ios={cloudDownloadOutline} md={cloudDownloadSharp} slot="icon-only"/>
            <IonIcon color="warning" ios={cloudUploadOutline} md={cloudUploadSharp} slot="icon-only"/>
            <IonSpinner slot="end" name="dots"/>
          </IonButtons>:<IonButtons slot="end" style={{margin:".5em"}}><IonIcon color="success" ios={cloudDoneOutline} md={cloudDoneSharp} slot="icon-only"/></IonButtons>}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {children}
      </IonContent>
    </>
  );
};

export default MenuPage;