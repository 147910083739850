import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonList, IonText } from "@ionic/react";
import { Redirect, Route, Switch, useParams } from "react-router";
import { StudentEnrollment, StudentEnrollmentFromServer } from "../../../data-types/Types";
import ClassOverview from "../components/ClassOverview";
import DoAssignment from "../components/DoAssignment";
import Expandable from "../components/Expandable";
import StudentLogin from "../components/StudentLogin";
import { useAuth } from "../services/AuthProvider";
import { useData } from "../services/DataProvider";
import MenuPage from "./MenuPage";

const StudentClassView:React.FC = ({ ...props})=>{
  const { classCode } = useParams<{classCode:string}>();
  //TODO: Direct to login, choose class code, or show overview, as appropriate.
  const {classCodes} = useData();
  const {studentSignIns} = useAuth();
  const className = classCodes.get(classCode)?.displayName;
  const title = className || "Student Login";
  const matchingSignIns = studentSignIns.filter((studentEnrollment)=>{return studentEnrollment.classCode===classCode});
  let studentEnrollment: StudentEnrollmentFromServer | undefined;
  if (matchingSignIns.length > 0) {
    studentEnrollment = matchingSignIns[0];
  }

  return <MenuPage title={title}>
    {studentEnrollment?
    <Switch>
      <Route path="/class/:classCode/assignment/:assignmentID" exact={true}>
        <DoAssignment />
      </Route>
      <Route path="/class/:classCode" exact={true}>
        <ClassOverview studentEnrollment={studentEnrollment} />
      </Route>
      <Route>
        <Redirect to="/404"/>
      </Route>
    </Switch>
    :
    <StudentLogin classCode={classCode}/>
    }
  </MenuPage>
}

export default StudentClassView;