import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonLabel, IonList, IonListHeader, IonProgressBar, IonText } from "@ionic/react";
import { Assignment, Quiz, StudentEnrollmentFromServer, StudentStatusFromServer } from "../../../data-types/Types";
import { useData } from "../services/DataProvider";
import CreateAssignment from "./CreateAssignment";
import Expandable from "./Expandable";
import Standard from "./Standard";

const ClassManagement:React.FC<{classID:string}> = ({classID, ...props})=>{
  const link = (window.location.protocol + "//") + window.location.hostname.toLowerCase() + (window.location.port? ":"+window.location.port : "") + "/class/" + classID;

  const {standards, teacherData} = useData();

  const enrolledStudents = (teacherData.classesToEnrollments?.[classID] || []) as StudentEnrollmentFromServer[];
  const assignments = (teacherData.classesToAssignments?.[classID] || []) as Assignment[];

  const assignmentsToSummaries = Object.fromEntries(assignments.map((assignment)=>{
    const statuses = (teacherData.assignmentsToStatuses?.[assignment.id || ""] || []) as StudentStatusFromServer[];
    const quizName = teacherData.quizzes?.filter((quiz: Quiz)=>{return quiz.id?.trim() === assignment.quizID.trim()})?.[0]?.name;
    const nextTopicCounts = new Map<string, number>();
    const masteredTopicCounts = new Map<string, number>();
    for (let status of statuses) {
      if (status.status === "complete") {
        for (let nextStandard of status.nextStandardsToReview) {
          nextTopicCounts.set(nextStandard, (nextTopicCounts.get(nextStandard)||0) + 1);
        }
        for (let masteredStandard of status.completedStandardIDs) {
          masteredTopicCounts.set(masteredStandard, (masteredTopicCounts.get(masteredStandard)||0) + 1);
        }
      }
    }
    const suggestedNextTopics = [...nextTopicCounts.entries()].sort((a, b) => b[1] - a[1])
    // TO get the above to compile, I had to allow a compiler option compatible with es5. https://stackoverflow.com/questions/49218765/typescript-and-iterator-type-iterableiteratort-is-not-an-array-type

    return [assignment.id, {
      completed: statuses.length,
      quizName: quizName,
      suggestedTopics: suggestedNextTopics.slice(0,3)
    }]
  }));

  return <>
  <IonCard>
    <IonCardHeader><IonCardTitle>Class Overview and Students</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <p>Link for students: <a href={link}>{link}</a></p>
      {/* <Expandable><IonText color="danger"><p>Please excuse the noise! This part of the site will be released after initial student data collection.</p></IonText></Expandable> */}
      <h2>Enrolled Students: {enrolledStudents.length}</h2>
      <Expandable>
        <IonText color="danger"><p>For data privacy reasons, you will not be able to identify students with their FinLitRI.org IDs except by communicating with them outside of this system.</p></IonText>
        <IonListHeader><IonLabel>Anonymous Student IDs</IonLabel></IonListHeader>
        <IonList>
          {enrolledStudents.map((enrolledStudent, index)=>{return <IonItem key={index}>{enrolledStudent.studentPassword}</IonItem>})}
        </IonList>
      </Expandable>
    </IonCardContent>
  </IonCard>

  {/* <IonCard>
    <IonCardHeader><IonCardTitle>Learning for Mastery</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <p><IonText color="medium">Scores are averaged across standards and students.</IonText></p>

      Idea: Add an expandable detail view with a kind of CDF of percentage of students at each amount of mastery. Also, add a detail view with a highlightable DAG showing individual student masteries.

      <h1>I. Earning Income</h1>
      Pre-Score: 45%
      <IonProgressBar color="primary" value={0.45}></IonProgressBar>
      <IonProgressBar color="success" value={0.96}></IonProgressBar>
      Post-Score: 96%<br />

      <h1>II. Spending</h1>
      Pre-Score: 37%
      <IonProgressBar color="primary" value={0.37}></IonProgressBar>
      <IonProgressBar color="success" value={0.95}></IonProgressBar>
      Post-Score: 95%<br />

      <h1>III. Saving</h1>
      Pre-Score: 39%
      <IonProgressBar color="primary" value={0.39}></IonProgressBar>
      <IonProgressBar color="success" value={0.92}></IonProgressBar>
      Post-Score: 92%<br />

      <h1>IV. Investing</h1>
      Pre-Score: 22%
      <IonProgressBar color="primary" value={0.22}></IonProgressBar>
      <IonProgressBar color="success" value={0.85}></IonProgressBar>
      Post-Score: 85%<br />

      <h1>V. Managing Credit</h1>
      Pre-Score: 9%
      <IonProgressBar color="primary" value={0.09}></IonProgressBar>
      <br /> 

      <h1>VI. Managing Risk</h1>
      Pre-Score: 9%
      <IonProgressBar color="primary" value={0.09}></IonProgressBar>
      <br /> 
    </IonCardContent>
  </IonCard> */}

  {/* <IonCard>
    <IonCardHeader><IonCardTitle>Impact Measurement</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <h1>Most Effective Topics</h1>
      {improvedTopics.map((standard, index)=>{return <Standard standard={standard} key={index}></Standard>})}
      <h1>Least Effective Topics</h1>
      {weakTopics.map((standard, index)=>{return <Standard standard={standard} key={index}></Standard>})}
    </IonCardContent>
  </IonCard> */}

  <IonCard>
    <IonCardHeader><IonCardTitle>Create New Assignment</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <Expandable>
        <CreateAssignment classID={classID}/>
      </Expandable>
    </IonCardContent>
  </IonCard>

  {assignments.map((assignment, index)=>{
    const summary = assignmentsToSummaries[assignment.id|| ""];
    return <IonCard key={assignment.id || index}>
      <IonCardHeader>
        <IonCardTitle>{assignment.title || ("Assigned Quiz: " + (assignmentsToSummaries[assignment.id|| ""]?.quizName || assignment.quizID))}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {assignment.description? <p><b>Description: </b>{assignment.description}</p>: <></>}
        <p>Completed by <b>{assignmentsToSummaries[assignment.id|| ""]?.completed}</b> of <b>{enrolledStudents.length}</b>.</p>

        <br />

        <h1>Classroom Recommendations</h1>
        {summary?.suggestedTopics.length > 0? <>
          <h2>Suggested Next Topics</h2>
          {summary?.suggestedTopics.map((nextTopic: [string, number], index: number)=>{
            const standardID = nextTopic[0];
            const numberOfStudents = nextTopic[1];
            return <div key={index}>
              <Standard standard={standards.filter((standard)=>{return standard.id === standardID})[0]}></Standard>
              <p>Needed and accessible for {numberOfStudents} students.</p>
            </div>
          })}
        </>
        :
        <><p>None. (It looks like there aren't any students with knowledge gaps in this data so far.)</p></>
        }
        {/* <h2>Students Needing Special Support</h2>
        <h2>Suggested Student Groups</h2> */}
      </IonCardContent>
    </IonCard>
  })}
  </>
}

export default ClassManagement;