import { IonRouterLink, IonText } from "@ionic/react";
import { FormEvent, useState } from "react";
// import { useHistory } from "react-router";
// import { useAuth } from "reactfire";
import { useAuth } from "../services/AuthProvider";

const LogOutButton: React.FC = ({...props}) => {
  const {logout} = useAuth(); 
  // const auth = useAuth();

  const [error, setError] = useState("");

  // const history = useHistory();

  async function handleLogOut(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    setError('');
    await (logout()?.then(()=>{
      // history.push("/"); //Redirect to home page/login after logout
      // REDIRECT SHOULD HAPPEN IN THE ROUTER.
    }).catch((reason)=>{
      setError('Failed to log out: ' + (reason.message || ""));
      //console.log(reason); //TODO: Present to the user.
    }));
  }

  //TODO: Set color of error based on ionic colors
  return <>
    <IonRouterLink onClick={handleLogOut} routerLink="/">[Log Out]</IonRouterLink>
    {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
  </>
};

export default LogOutButton;

