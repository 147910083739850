import { IonIcon} from '@ionic/react';
import { helpCircleOutline, helpCircle } from 'ionicons/icons';

import './Tooltip.css';

const Tooltip: React.FC<{helpText:string}> = ({helpText, children, ...props}) => {
  //Alternately, can use the title attribute on a span to add tooltip text, but it is slow and un-style-able.
  return (
    <span className="tooltip">
      <div className="help-text"><p>{helpText}</p></div>
      {children}
      <IonIcon className="help-icon" ios={helpCircleOutline} md={helpCircle}/>
    </span>
  );
};

export default Tooltip;