import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText, IonTextarea } from "@ionic/react";
import { FormEvent, useRef, useState } from "react";
import { Assignment } from "../../../data-types/Types";
import { useData } from "../services/DataProvider";

const CreateAssignment:React.FC<{classID:string}> = ({classID, ...props})=>{
  const [error, setError] = useState("");
  const {synching, createOwnedRecord} = useData();
  const quizSelectRef = useRef<HTMLIonSelectElement>(null);
  const titleRef = useRef<HTMLIonInputElement>(null);
  const descriptionRef = useRef<HTMLIonTextareaElement>(null);

  const topicQuizIDs = {
    "4": {
      "I. Earning Income": "yfUPIKYDK7Q7gPZhhwIW",
      "II. Spending": "w6Ky1miYyeDyqL2q2d2H", 
      "III. Saving": "ood6qfCWAcwbyr6SCcQi",
      "IV. Investing": "FrBvtLW5pvFm9E6goBzi",
      "V. Managing Credit": "EUDCyCe7rOGgi9KkpWKt",
      "VI. Managing Risk": "5w9Pm74NlFSOewcu2xsj"
    },
    "8": {
      "I. Earning Income": "hFo5y7X3pli2iXOE7qqB",
      "II. Spending": "QARrHEfsOBTIzEw8Ishw", 
      "III. Saving": "vD8j3IWPJ7WdYDMGryOe",
      "IV. Investing": "n48EFoQwWhUf39RDOGRo",
      "V. Managing Credit": "iTB9H61oiEiNTY07s6A3",
      "VI. Managing Risk": "SiXY5Rj4ic690lxzsLtN"
    },
    "12": {
      "I. Earning Income": "ZmQZtYyOu6zCmqKy0oNF",
      "II. Spending": "wflAiJqOFTA7IGSMU0BU", 
      "III. Saving": "sshfwH3eNEcEGmblusDD",
      "IV. Investing": "T4Ki1Jj4hKFcwwgPtnyu",
      "V. Managing Credit": "KLlJ4vaBGlpzDKe1XRMt",
      "VI. Managing Risk": "oxZy6OAohlpwbRXByi9E"
    },
  };

  const cumulativeQuizzes = {
    "Comprehensive Quiz (4th Grade Standards)": "p6XUren59FKRZ0v83BNN",
    "Comprehensive Quiz (All Grades Pre-Test/Post-Test)": "CQLSM4uOiYtbtWfCLqKS"
  };

  const allowedQuizzes = {
    "Comprehensive Quiz (4th Grade Standards)": "p6XUren59FKRZ0v83BNN"
  }

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.
    // TODO
    setError("");

    const assignment = {
      classCode: classID,
      quizID: quizSelectRef.current?.value || "",
      title: "" + titleRef.current?.value,
      description: "" + descriptionRef.current?.value,
      requiredQuestions: 20,
    }

    if (assignment.quizID.length <= 0) {
      setError("Please select a quiz to assign.");
    } else if (assignment.title?.length <= 1) {
      setError("Please give the assignment a descriptive title. (For example, is this a post-test, a pre-test, a topical test, or just a review activity?)");
    } else {
      createOwnedRecord("assignments", assignment).catch((reason) => {
        setError('Failed to create assignment: ' + (reason.message || ""));
      })
    }
  }

  return <form onSubmit={handleSubmit}>
    {error.length > 0? <IonText color="danger">{error}</IonText>: <></>}
    <IonItem>
      <IonLabel>Quiz to Assign</IonLabel>
      <IonSelect ref={quizSelectRef} interface="popover" style={{"max-width":"100%"}}>
        {Object.keys(allowedQuizzes).map((quiz, index)=>{return <IonSelectOption key={index} value={allowedQuizzes[quiz as keyof typeof allowedQuizzes]}>{quiz}</IonSelectOption>})}
      </IonSelect>
    </IonItem>
    <IonItem>
      <IonLabel position="floating">Assignment Name</IonLabel>
      <IonInput type="text" ref={titleRef}/>
    </IonItem>
    <IonItem>
      <IonLabel position="floating">Description</IonLabel>
      <IonTextarea ref={descriptionRef} autoGrow/>
    </IonItem>
    <IonButton expand="block" type="submit" className="ion-margin-top" disabled={synching}>
      Create
    </IonButton>
  </form>
}

export default CreateAssignment;