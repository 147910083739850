import { IonText } from "@ionic/react";
import { useState } from "react";

const Expandable:React.FC = ({children, ...props}) =>{
  const [expanded, setExpanded] = useState(false);

  return <>
    <IonText className="clickable" color="secondary" onClick={()=>{setExpanded(!expanded)}}><p>{!expanded? <>... [Click to expand.]</> : <>[Click to collapse.]</>}</p></IonText>
    {expanded? children : <></>}
  </>
};

export default Expandable