import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonText, IonItem, IonLabel, IonInput, IonButton } from "@ionic/react"
import { useRef, useState } from "react";
import { useAuth } from "../services/AuthProvider";
import Expandable from "./Expandable";

const DeleteProfile:React.FC = ()=>{
  const {currentUser, deleteAccount} = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const emailConfirmRef = useRef<HTMLIonInputElement>(null);

  const handleDelete = async ()=>{
    if (emailConfirmRef.current?.value !== currentUser?.email) {
      setError("You must enter exactly the email of this account in order to delete it.")
      return;
    }

    setError('');
    setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
    await (deleteAccount()?.then(()=>{
      setLoading(false);
      // history.push("/"); //Redirect to home page/dashboard after login.
      // REDIRECT SHOULD HAPPEN IN THE ROUTER.
    }).catch((reason)=>{
      setError('Failed to delete account: ' + (reason.message || ""));
      //console.log(reason); //TODO: Present to the user.
      setLoading(false);
    }));
  }

  return <IonCard>
    <IonCardHeader><IonCardTitle><IonText color="danger">Delete Profile</IonText></IonCardTitle></IonCardHeader>
    <IonCardContent>
      <p>This action will remove your email and other associated account information from this website. This action is irreversible, and will remove all personal information associated with this account.</p>
      <Expandable>
        {error? <IonText color="danger"><p>{error}</p></IonText> : <></>}
        <p>To confirm the deletion, please type your account email (listed in the page menu) below.</p>
        <IonItem>
          <IonLabel position="floating"><IonText color="danger">Confirm Email of Account to Delete</IonText></IonLabel>
          <IonInput ref={emailConfirmRef} color="danger"/>
        </IonItem>
        <IonButton color="danger" onClick={handleDelete} disabled={loading}>Delete Account</IonButton>
      </Expandable>
    </IonCardContent>
  </IonCard>
  
  
}

export default DeleteProfile;