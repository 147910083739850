import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonRouterLink, IonText } from "@ionic/react";
import { FormEvent, useRef, useState } from "react";
// import { useHistory } from "react-router";
import { useAuth } from "../services/AuthProvider";
import { useData } from "../services/DataProvider";

const StudentLogin: React.FC<{classCode:string}> = ({classCode, ...props}) => {
  const studentPasswordRef = useRef<HTMLIonInputElement>(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {logInStudent, createEnrollment} = useAuth();
  const {classCodes} = useData();

  const className = classCodes.get(classCode)?.displayName;

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    setError('');
    setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
    logInStudent(classCode, "" + studentPasswordRef.current?.value).then((result)=>{
      setLoading(false);
      // Nothing else to do; higher-level components are responsible for the redirect.
    }).catch((reason)=>{
      setLoading(false);
      setError(reason.message);
    })

    // await (login("" + emailRef.current?.value, "" + passwordRef.current?.value)?.then(()=>{
    //   // setLoading(false);
    //   // history.push("/"); //Redirect to home page/dashboard after login.
    //   // REDIRECT SHOULD HAPPEN IN THE ROUTER.
    // }).catch((reason)=>{
    //   setError('Failed to log in: ' + (reason.message || ""));
    //   setLoading(false);
    //   //console.log(reason); //TODO: Present to the user.
    // }));
  }
  
  async function handleCreateEnrollment() {
    setError('');
    setLoading(true);
    createEnrollment(classCode).then((result)=>{
      setLoading(false);
      // Nothing to do here. Higher-level components will reroute based on permissions.
    }).catch((reason)=>{
      setError(reason.message);
      setLoading(false)
    });
  }

  //TODO: Set color of error based on ionic colors
  return <IonCard>
    <IonCardHeader><IonCardTitle>Anonymous Student Login</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <h1>First time user?</h1>
      <IonButton color="secondary" expand="block" className="ion-margin-top" disabled={loading} onClick={handleCreateEnrollment}>
        Create New Student Account
      </IonButton>
      
      <h1 style={{textAlign:"center"}}>OR</h1>
      {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
      <form onSubmit={handleSubmit}>
        <IonItem>
          <IonLabel position="floating">Student Password{className? " for " + className: ""}</IonLabel>
          <IonInput type="text" ref={studentPasswordRef} placeholder="87 smart students learn"/>
        </IonItem>
        <IonButton expand="block" type="submit" className="ion-margin-top" disabled={loading}>
          Log In
        </IonButton>
      </form>
    </IonCardContent>
  </IonCard>
};

export default StudentLogin;

