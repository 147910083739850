import { IonApp, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global-ionic.css'; // Add some stylings to make ionic cards nicer on desktop.

import { initializeApp } from "firebase/app";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import AppRouter from './AppRouter';
import AuthProvider from './services/AuthProvider';
import DataProvider from './services/DataProvider';

setupIonicReact();

const firebaseConfig = {
  apiKey: "AIzaSyA4UlhoZLSnaMLctrjV2-ttfb_W_mfVg_Q",
  authDomain: "edapt-app.firebaseapp.com",
  projectId: "edapt-app",
  storageBucket: "edapt-app.appspot.com",
  messagingSenderId: "593303529751",
  appId: "1:593303529751:web:8ac163d4031f6914676f52",
  measurementId: "G-3XQHVM8D9T"
};

const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
if (window.location.hostname.toLowerCase() == "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001); //TODO: Remove
}
export {firebaseApp, functions}//, firebaseFunctions}; // Since it's pretty unimaginable to be running two firebase APIs in one frontend, this wil just be a global export, not a context provider.
// However, uses of the object (auth, firestore) will have related context providers.

const App: React.FC = () => {
  return (
    <AuthProvider>
      <DataProvider>
        <IonApp>
          <AppRouter />
        </IonApp>
      </DataProvider>
    </AuthProvider>
  );
};

export default App;

