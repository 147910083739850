import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonRouterLink, IonText } from "@ionic/react";
import { FormEvent, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useData } from "../services/DataProvider";

const ClassSelector: React.FC = ({...props}) => {
  const classCodeRef = useRef<HTMLIonInputElement>(null);
  const {checkClassCode, synching} = useData();

  const [error, setError] = useState("");

  const history = useHistory();

  const loadClass = (event:FormEvent) => {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    const classCode = "" + classCodeRef.current?.value
    setError("");
    if (classCode.length < 1) {
      setError("Class Code cannot be empty.");
    } else {
      checkClassCode(classCode).then((result)=>{
        history.push("/class/" + classCode);
      }).catch((reason)=>{
        setError(reason.message);
      })
    }
  }

  //TODO: Set color of error based on ionic colors
  return <IonCard>
    <IonCardHeader><IonCardTitle>Anonymous Student Login</IonCardTitle></IonCardHeader>
    <IonCardContent>
      {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
      <form onSubmit={loadClass}>
        <IonItem>
          <IonLabel position="floating">Class Code</IonLabel>
          <IonInput type="text" ref={classCodeRef} placeholder="e.g. 012345"/>
        </IonItem>
        <IonButton expand="block" type="submit" className="ion-margin-top" disabled={synching}>
          Enter Class
        </IonButton>
      </form>
    </IonCardContent>
  </IonCard>
};

export default ClassSelector;

